import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import './SectionBoxes.css';

import database from '../../database.json'

class SectionBoxes extends Component {

    constructor(props)
    {
        super(props);

        this.handleEducateClick = this.handleEducateClick.bind(this);
        this.handleMarketClick  = this.handleMarketClick.bind(this);
        this.handleEnableClick  = this.handleEnableClick.bind(this);
        this.handleDeliverClick = this.handleDeliverClick.bind(this);
        this.handleRecoverClick = this.handleRecoverClick.bind(this);
    }

    handleEducateClick(e)
    {
        this.props.changeContentFunc('educate');
    }

    handleMarketClick(e)
    {
        this.props.changeContentFunc('market');
    }

    handleEnableClick(e)
    {
        this.props.changeContentFunc('enable');
    }

    handleDeliverClick(e)
    {
        this.props.changeContentFunc('deliver');
    }

    handleRecoverClick(e)
    {
        this.props.changeContentFunc('recover');
    }
    
    render()
    {
        var educateClasses  = "";
        var marketClasses   = "";
        var enableClasses   = "";
        var deliverClasses  = "";
        var recoverClasses  = "";

        switch (this.props.hidePillar)
        {
            case 'Educate':
                educateClasses = "d-none";
                break;

            case 'Market':
                marketClasses = "d-none";
                break;  

            case 'Enable':
                enableClasses = "d-none";
                break;  

            case 'Deliver':
                deliverClasses = "d-none";
                break;

            case 'Recover':
                recoverClasses = "d-none";
                deliverClasses = "border-bottom";
                break;
                
            default:
                break;
        }

        var sectionEducate  = database.data_sections.educate;
        var sectionMarket   = database.data_sections.market;
        var sectionEnable   = database.data_sections.enable;
        var sectionDeliver  = database.data_sections.deliver;
        var sectionRecover  = database.data_sections.recover;

        return (
            <Container className="container_pillarBoxes pt-5 pb-5">
                <Row className="justify-content-center">
                    <Col
                        xs="10"
                        className={"pt-5 pb-4 text-center border-left border-right border-top " + educateClasses}
                        onClick={this.handleEducateClick}>
                        <img
                            src={sectionEducate.headerImage}
                            alt=""
                            className="img-fluid mb-3"
                            width="200px"
                        />
                        <p>{sectionEducate.section}</p>
                    </Col>

                    <Col
                        xs="10"
                        className={"pt-5 pb-4 text-center border-left border-right border-top " + marketClasses}
                        onClick={this.handleMarketClick}>
                        <img
                            src={sectionMarket.headerImage}
                            alt=""
                            className="img-fluid mb-3"
                            width="200px"
                        />
                        <p>{sectionMarket.section}</p>
                    </Col>

                    <Col
                        xs="10"
                        className={"pt-5 pb-4 text-center border-left border-right border-top " + enableClasses}
                        onClick={this.handleEnableClick}>
                        <img
                            src={sectionEnable.headerImage}
                            alt=""
                            className="img-fluid mb-3"
                            width="200px"
                        />
                        <p>{sectionEnable.section}</p>
                    </Col>

                    <Col
                        xs="10"
                        className={"pt-5 pb-4 text-center border-left border-right border-top " + deliverClasses}
                        onClick={this.handleDeliverClick}>
                        <img
                            src={sectionDeliver.headerImage}
                            alt=""
                            className="img-fluid mb-3"
                            width="200px"
                        />
                        <p>{sectionDeliver.section}</p>
                    </Col>

                    <Col
                        xs="10"
                        className={"pt-5 pb-4 text-center border-left border-right border-top border-bottom " + recoverClasses}
                        onClick={this.handleRecoverClick}>
                        <img
                            src={sectionRecover.headerImage}
                            alt=""
                            className="img-fluid mb-3"
                            width="200px"
                        />
                        <p>{sectionRecover.section}</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default SectionBoxes;