import React, { Component } from 'react';

import './App.css';

import LayoutDesktop from './Layouts/LayoutDesktop/LayoutDesktop';
import LayoutMobile from './Layouts/LayoutMobile/LayoutMobile';

class App extends Component
{
    isMobile()
    {
        if (window.innerWidth <= 540)
        {
            return true;
        }

        return false;
    }

    render()
    {

        var layout;

        if (this.isMobile())
        {
            layout = <LayoutMobile />;
        }
        else
        {
            layout = <LayoutDesktop />;
        }

        return (
            <React.Fragment>
                {layout}
            </React.Fragment>
        );
    }
}

export default App;