import React, { Component } from 'react';

class ArrowLink extends Component
{
    constructor(props)
    {
        super(props);
        this.handleMouseOver            = this.handleMouseOver.bind(this);
        this.handleMouseOut             = this.handleMouseOut.bind(this);
		this.handleMouseClick 			= this.handleMouseClick.bind(this);
        this.executeMouseOverActions    = this.executeMouseOverActions.bind(this);
        this.executeMouseOutActions     = this.executeMouseOutActions.bind(this);

        this.state = {
            normalSrc: this.props.normalSrc,
            hoverSrc: this.props.hoverSrc,
            activeSrc: this.props.activeSrc,
            currentSrc: this.props.normalSrc,
            arrowLinkHoverClass: "",
        }
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return (nextState.currentSrc !== this.state.currentSrc ||
                nextState.arrowLinkHoverClass !== this.state.arrowLinkHoverClass
        );
    }

    handleMouseOver(e)
    {
        // Callback to parent IngramCircleBlue
        this.props.onArrowLinkMouseOver(this);
	
		// Set cursor to be mouse over
		this.setState({
			arrowLinkHoverClass: "hover"
        });		
        
        // Activate corresponding pillar icon
        this.props.pillarIcon.current.activateHoverState(true);
    }

    handleMouseOut(e)
    {
		// Callback to parent IngramCircleBlue
        this.props.onArrowLinkMouseOut(this);
		
		// Remove cursor hand styling
        this.setState({
            arrowLinkHoverClass: ""
        });

        // Deactivate corresponding pillar icon
        this.props.pillarIcon.current.activateHoverState(false);
    }
	
	handleMouseClick(e)
	{
		// Hide the hover popup
		this.props.popup.current.showPopup(false);
		
		
        this.props.openInfoContainerFunc(this.props.id);
	}

    executeMouseOverActions()
    {
        this.changeImageHoverOn();
        
        // Popups disabled -uschej03 2/10/2022
        //this.props.popup.current.showPopup(true);
    }

    executeMouseOutActions()
    {
        this.changeImageDefault();

        // Popups disabled -uschej03 2/10/2022
        //this.props.popup.current.showPopup(false);
    }



    // Sets image to hover state
    changeImageHoverOn()
    {
        this.setState({
            currentSrc: this.props.hoverSrc
        });
    }

    // Sets back to default/resting image
    changeImageDefault()
    {
        this.setState({
            currentSrc: this.props.normalSrc
        });
    }

    // Sets back image to active state
    changeImageActiveOn()
    {
        this.setState({
            currentSrc: this.props.activeSrc
        });
    }

    render()
    {
        return (
            <img
                id={this.props.id}
                src={this.state.currentSrc}
                className={"arrowLink " + this.props.className + " " + this.state.arrowLinkHoverClass}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
                alt=""
				onClick={this.handleMouseClick}
                />
        );
    }
}

export default ArrowLink;