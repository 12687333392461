import React, { Component } from 'react';
import {
    Row,
    Col,
} from 'reactstrap';

import '../../InfoContainer/InfoContainer.css';
import database from '../../database.json'

class SectionContainer extends Component {

    constructor(props)
    {
        super(props);

        this.sectionData = database.data_sections;
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return nextProps.section !== this.props.section;
    }

    render()
    {
        // Mapping props to json section data
        var sectionContent;

        switch (this.props.section)
        {
            case "educate":
                sectionContent = this.sectionData.educate;
                break;
            case "market":
                sectionContent = this.sectionData.market;
                break;
            case "enable":
                sectionContent = this.sectionData.enable;
                break;
            case "deliver":
                sectionContent = this.sectionData.deliver;
                break;
            case "recover":
                sectionContent = this.sectionData.recover;
                break;
            default:
                break;
        }

        var reactFragment;

        if (sectionContent)
        {
            // Build the services
            var services = sectionContent.services.map((service) =>
            {
                var serviceInfoLinkBlock;
                var serviceContactLinkBlock;
                
                if (service.hasOwnProperty("infoLink"))
                {
                    serviceInfoLinkBlock =  <a href={service.infoLink} target="_blank" rel="noopener noreferrer">
                                                <img className="mr-1 img_iconInfo" alt=""/>
                                            </a>
                }

                if (service.hasOwnProperty("contactLink"))
                {
                    serviceContactLinkBlock =   <a href={service.contactLink} target="_blank" rel="noopener noreferrer">
                                                    <img className="ml-1 img_iconContact" alt=""/>
                                                </a>
                }

                return (
                    <div className="mb-5">
                        <h3>{service.title}</h3>
                        <p dangerouslySetInnerHTML={{__html: service.description}} />

                        {serviceInfoLinkBlock}
                        {serviceContactLinkBlock}
                    </div>
                );
            });


            reactFragment =
                <React.Fragment>
                    <div className="vh-100 overflow-auto pr-3">
                        <Row>
                            <Col xs={{size: 4}}>
                                <img
                                    src={sectionContent.headerImage}
                                    className="mt-3 mb-4 ml-3 img-fluid"
                                    alt=""
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <h1>{sectionContent.section}</h1>
                                <h2 className="mb-5">{sectionContent.intro}</h2>

                                {services}

                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
        }
        else
        {
            reactFragment = "";
        }

        return reactFragment;
    }
}

export default SectionContainer;