import React, { Component } from 'react';

import ContentHome from './ContentHome';
import ContentSearch from './ContentSearch';
import ContentSection from './ContentSection';

class ContentContainer extends Component {
    
    render()
    {
        var content;

        switch (this.props.contentPage)
        {
            case 'educate':
            case 'market':
            case 'enable':
            case 'deliver':
            case 'recover':
                content = <ContentSection
                            changeContentFunc={this.props.changeContentFunc}
                            section={this.props.contentPage} /> 
                break;

            case 'search':
                content = <ContentSearch />;
                break;

            case 'home':
            default:
                content = <ContentHome 
                                hideContent={this.props.hideContent}
                                changeContentFunc={this.props.changeContentFunc}
                            />;
                break;
        }


        return (
            <React.Fragment>
                {content}
            </React.Fragment>           
        );
    }
}

export default ContentContainer;