import React, { Component } from 'react';

import './IngramCircleWhite.css';

import Popup from "../Popup/Popup"
import IconLabel from "./IconLabel/IconLabel"

import IngramCirclePng from './img/ingram_white.png'

import CloudLine from './img/cloud_line.png'
import TechSolLine from './img/tech_solutions_line.png'
import CommLifeCycleLine from './img/comm_lifecycle_line.png'

import CloudIcon from './img/cloud_icon.png'
import CommLifeCycleIcon from './img/comm_lifecycle_icon.png'
import TechSolIcon from './img/technology_solutions_icon.png'

class IngramCircleWhite extends Component {

    constructor(props)
    {
        super(props);

        this.handleMouseClick = this.handleMouseClick.bind(this);
        this.handleMouseOver  = this.handleMouseOver.bind(this);
        this.handleMouseOut   = this.handleMouseOut.bind(this);

        this.state = {
            currentActiveIconLabel: null,
            hideLineClass: "",
            hideIconLabelClass: "",
            circleHoverClass: '',
        }

        this.popupCloud         = React.createRef();
        this.popupCommLifeCycle = React.createRef();
        this.popupTechSol       = React.createRef();
    }

    handleMouseClick(e)
    {
        console.log(this);
        if (!this.props.isInfoContainerOpen)
        {
            this.props.switchCircleFunc();
        }
    }

    handleMouseOver(e)
    {
        this.setState({
            circleHoverClass: "hover"
        });
    }

    handleMouseOut(e)
    {
        this.setState({
            circleHoverClass: ""
        });
    }


    handleIconLabelMouseOver(iconLabelObj)
    {
        if (!this.props.isInfoContainerOpen)
        {
            if (this.state.currentActiveIconLabel != null)
            {
                this.state.currentActiveIconLabel.executeMouseOutActions();
            }

            iconLabelObj.executeMouseOverActions();


            this.setState({
                currentActiveIconLabel: iconLabelObj
            });
        }
    }

    hideExtraElements()
    {
        // Hide lines and icon labels
        this.setState({
            hideLineClass: 'hide',
            hideIconLabelClass: 'hide',
        });
    }

    showExtraElements()
    {
        // Show lines and icon labels
        this.setState({
            hideLineClass: '',
            hideIconLabelClass: '',
        });
    }

    render()
    {
        return (
            <div
                id="div_ingramCircleWhite"
                className={"h-100 " + this.props.className}>

                <img
                    id="img_ingramCircleWhite"
                    src={IngramCirclePng}
                    className={this.state.circleHoverClass}
                    onClick={this.handleMouseClick}
                    onMouseOver={this.handleMouseOver}
                    onMouseOut={this.handleMouseOut}
                    alt=""
                />

                <img
                    id="img_cloudLine"
                    src={CloudLine}
                    className={this.state.hideLineClass}
                    alt=""
                />

                <img
                    id="img_techSolLine"
                    src={TechSolLine}
                    className={this.state.hideLineClass}
                    alt=""
                />

                <img
                    id="img_commLifeCycleLine"
                    src={CommLifeCycleLine}
                    className={this.state.hideLineClass}
                    alt=""
                />

                <IconLabel
                    id="iconlabel_cloud"
                    src={CloudIcon}
                    className={this.state.hideIconLabelClass}
                    labelText="Cloud"
                    onIconLabelMouseOver={this.handleIconLabelMouseOver.bind(this)}
                    popup={this.popupCloud}
                />

                <IconLabel
                    id="iconlabel_commLifeCycle"
                    src={CommLifeCycleIcon}
                    className={this.state.hideIconLabelClass}
                    labelText="Commerce & Lifecycle Services"
                    onIconLabelMouseOver={this.handleIconLabelMouseOver.bind(this)}
                    popup={this.popupCommLifeCycle}
                />

                <IconLabel
                    id="iconlabel_techSol"
                    src={TechSolIcon}
                    className={this.state.hideIconLabelClass}
                    labelText="Technology Solutions"
                    onIconLabelMouseOver={this.handleIconLabelMouseOver.bind(this)}
                    popup={this.popupTechSol}
                />

                <Popup
                    ref={this.popupCloud}
                    id="popup_cloud"
                    headerText="Compete and grow in a digital economy"
                    openInfoContainerFunc={this.props.openInfoContainerFunc}
                    alignment="left"
                />

                <Popup
                    ref={this.popupCommLifeCycle}
                    id="popup_commLifeCycle"
                    headerText="Unparalleled global reach"
                    openInfoContainerFunc={this.props.openInfoContainerFunc}
                    alignment="left"
                />

                <Popup
                    ref={this.popupTechSol}
                    id="popup_techSol"
                    headerText="Your competitive advantage"
                    openInfoContainerFunc={this.props.openInfoContainerFunc}
                    alignment="left"
                />


            </div>
        );
    }
}

export default IngramCircleWhite;