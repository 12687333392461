import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import './SearchResults.css';

import IconSearchPng from "./img/icon_search.png"
import BackButtonPng from "./img/backbutton.png"

class SearchResults extends Component {

    constructor(props)
    {
        super(props);

        this.handleBackButtonClick  = this.handleBackButtonClick.bind(this);
        this.handleSearchSubmit     = this.handleSearchSubmit.bind(this);

        this.childKey = 0;  // Forces the input field to destroy/remount everytime it is rendered
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return (
            nextProps.displaySearchResults !== this.props.displaySearchResults ||
            nextProps.results !== this.props.results ||
            nextProps.searchQuery !== this.props.searchQuery
        );
    }

    handleBackButtonClick(e)
    {
        this.props.closeSearchWindowFunc();
    }

    handleSearchSubmit(e)
    {
        if (e.key === "Enter")
        {
            var searchInput = e.target.value;

            this.props.searchFunc(searchInput);
        }   
    }

    render()
    {
        var searchResults = this.props.results.map((result) =>
        {
            if (result.contact === "")
            {
                return <div key={result.keyword} className="div_searchResult mb-4">
                    <p>
                        {result.keyword}
                    </p>

                    <a href={result.learnMore} target="_blank" rel="noopener noreferrer">
                        <img className="mr-1 img_iconInfo" alt=""/>
                    </a>

                    <hr />
                </div>
            }
            else if (result.learnMore === "")
            {
                return <div key={result.keyword} className="div_searchResult mb-4">
                    <p>
                        {result.keyword}
                    </p>

                    <a href={result.contact} target="_blank" rel="noopener noreferrer">
                        <img className="ml-1 img_iconContact" alt=""/>
                    </a>

                    <hr />
                </div>
            }
            else
            {
                return <div key={result.keyword} className="div_searchResult mb-4">
                    <p>
                        {result.keyword}
                    </p>

                    <a href={result.learnMore} target="_blank" rel="noopener noreferrer">
                        <img className="mr-1 img_iconInfo" alt=""/>
                    </a>

                    <a href={result.contact} target="_blank" rel="noopener noreferrer">
                        <img className="ml-1 img_iconContact" alt=""/>
                    </a>

                    <hr />
                </div>
            }
        });

        var containerClasses = "";
        if (!this.props.displaySearchResults)
        {
            containerClasses += " hidden"
        }

        ++this.childKey;

        return (

            <Container fluid
                id="container_searchResults"
                className={"h-100 " + containerClasses}>
                
                <Row
                    className="h-100">
                    <Col
                        sm={{size: 4}}
                        xs={{size: 12}}
                        className="pl-0 pr-0">
                            
                        <div id="div_backButton" className="d-none d-sm-block">
                            <img src={BackButtonPng} onClick={this.handleBackButtonClick} alt="Back" />
                            <p className="text-uppercase" onClick={this.handleBackButtonClick}>Back</p>
                        </div>

                        <div
                            id="div_searchResults"
                            className="pt-5 pl-5 pr-5">

                            <div className="font-weight-bold mb-4">
                                {this.props.results.length} Results
                            </div>

                            <div>
                                <img id="img_searchIcon" src={IconSearchPng} className="d-inline" alt="Search" />
                                <input
                                    key={this.childKey}
                                    id="input_secondarySearch"
                                    type="text"
                                    className="form-control mb-5 text-center d-inline"
                                    placeholder={this.props.placeHolder}
                                    onKeyDown={this.handleSearchSubmit} />
                            </div>

                            <div>
                                {searchResults}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default SearchResults;