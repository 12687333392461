import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';

import './Popup.css'

import PopupIconLeftAligned from './popup_icon.png'
import PopupIconRightAligned from './popup_icon_right.png'

class Popup extends Component {

    constructor(props)
    {
        super(props);

        this.handleButtonClick = this.handleButtonClick.bind(this);

        this.state = {
            isHidden: true,
        }
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return nextState.isHidden !== this.state.isHidden;
    }

    handleButtonClick(e)
    {
        this.showPopup(false);
        this.props.openInfoContainerFunc(this.props.id);
    }

    showPopup(show)
    {
        if (show)
        {
            this.setState({
                isHidden: false,
            });
        }
        else
        {
            this.setState({
                isHidden: true,
            });
        }
    }


    render()
    {
        var columnOrder;
        var textJustify;
        var popupIcon;

        switch (this.props.alignment)
        {
            case "right":
                columnOrder = 1;
                textJustify = "text-right";
                popupIcon = PopupIconRightAligned;
                break;

            default:
                columnOrder = 0;
                popupIcon = PopupIconLeftAligned
        }

        var visibilityClass;

        if (!this.state.isHidden)
        {
            visibilityClass = "show";
        }

        return (
            <Container
                id={this.props.id}
                className={'popup ' + visibilityClass}
            >

                <Row>
                    <Col xs={{size: 4, order: columnOrder}}>
                        <img
                            src= {popupIcon}
                            alt=""
                        />
                    </Col>
                    <Col className="popupCopyMargin mb-3">
                        <h2 className={"text-uppercase " + textJustify}>{this.props.headerText}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ offset: 4 }}>
                        {/*<Button
                            className="popupButtonMargin"
                            onClick={this.handleButtonClick}
                        >
                            <span className="text-uppercase">Explore</span>
                        </Button>*/}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Popup;