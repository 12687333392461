import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import './WindowSearch.css';
import database from '../database.json'

import SearchResults from '../SearchResults/SearchResults';

import SearchIcon from "./img/icon_search_white.png"
import BackButton from "./img/backbutton_noline.png"

class WindowSearch extends Component
{
    constructor(props)
    {
        super(props);

        this.handleSearchSubmit             = this.handleSearchSubmit.bind(this);
        this.handleCompleteListingClick     = this.handleCompleteListingClick.bind(this);
        this.handleBackButtonClick          = this.handleBackButtonClick.bind(this);
        this.search                         = this.search.bind(this);     
        this.resetSearch                    = this.resetSearch.bind(this);   

        // Deprecating separate search database -uschej03 2/21/22
        //this.searchDatabase = database.data_search;

        this.searchDatabase = this.buildSearchDatabase(database.data_sections);

        this.state = {
            displaySearchResults: false,
            placeHolder: "Search",
            results: [],
        }

    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return (
            nextState.displaySearchResults !== this.state.displaySearchResults ||
            nextState.results !== this.state.results ||
            nextState.placeHolder !== this.state.placeHolder ||
            nextProps.className !== this.props.className
        );
    }

    buildSearchDatabase(data_sections)
    {
        var searchDatabase = [];

        console.log(data_sections);

        data_sections.deliver.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        data_sections.educate.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        data_sections.enable.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        data_sections.market.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        data_sections.recover.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        return searchDatabase;
    }

    handleSearchSubmit(e)
    {
        if (e.key === "Enter")
        {
            var searchInput = e.target.value;

            this.search(searchInput); 

            this.openSearchResults();
        }       
    }

    handleCompleteListingClick(e)
    {
        this.search("");
        this.openSearchResults();
    }

    handleBackButtonClick(e)
    {
        this.resetSearch();
        this.props.changeWindowFunc("main");
    }

    search(query)
    {
        var results = [];

        if (query === "")
        {
            results = this.searchDatabase;
            query = "Search";
        }
        else
        {
            this.searchDatabase.forEach(
            function (element)
            {
                if (element.keyword.toLowerCase().includes(query.toLowerCase()))
                {
                    results.push(element);
                }
            });
        }

        this.setState({
            results: results,
            placeHolder: query,
        });
    }

    openSearchResults()
    {
        this.setState({
            displaySearchResults: true, // Opens the search results window
        });
    }

    resetSearch()
    {
        this.setState({
            results: this.searchDatabase,
            displaySearchResults: false,
            query: "Search",
        });
    }

    render()
    {

        var mainSearchClasses = "";

        if (this.state.displaySearchResults)
        {
            mainSearchClasses += " hidden";
        }
    
        return (

            <Container fluid
                id="container_windowSearch"
                className={"h-100 " + this.props.className}>

                <Row
                className="h-100 justify-content-center">
                
                    <Col
                        xs={{size: 6}}
                        className="d-flex align-items-center">

                        <div
                            id="div_mainSearch"
                            className={"w-100 text-center" + mainSearchClasses}>

                            <div
                                className="input-group mb-5">
                                <input
                                    id="input_mainSearch"
                                    type="text"
                                    className="form-control text-center"
                                    placeholder="Find contacts and resources"
                                    onKeyDown={this.handleSearchSubmit} />
                                    <div
                                        className="input-group-append">
                                        <span
                                            className="input-group-text">
                                            <img
                                                src={SearchIcon}
                                                height="25px"
                                                alt="Search"
                                            />
                                        </span>
                                    </div>
                            </div>
                                
                            <button
                                type="button"
                                className="btn btn-search text-uppercase px-4 font-weight-bold"
                                onClick={this.handleCompleteListingClick}>
                                    View Complete Listing
                            </button>
                        </div>

                    </Col>
                </Row>

                <SearchResults 
                    results={this.state.results}
                    displaySearchResults={this.state.displaySearchResults}
                    closeSearchWindowFunc={this.handleBackButtonClick}
                    searchFunc={this.search} 
                    placeHolder={this.state.placeHolder}/>

                <div id="div_windowSearchBackButton">
                    <img
                        src={BackButton} alt="Back"
                        onClick={this.handleBackButtonClick} />
                    <p className="text-uppercase" onClick={this.handleBackButtonClick}>Back</p>
                </div>
            </Container>
        );
    }
}

export default WindowSearch;