import React, { Component } from 'react';
import {
    Container,
} from 'reactstrap';

import './WindowMain.css';
import IngramCircleBlue from "../IngramCircle/IngramCircleBlue/IngramCircleBlue";
import InfoContainer from "../InfoContainer/InfoContainer"
import IngramCircleWhite from "../IngramCircle/IngramCircleWhite/IngramCircleWhite";

class WindowMain extends Component {

    constructor(props)
    {
        super(props);

        this.blinkHoleToSmall = this.blinkHoleToSmall.bind(this);
        this.blinkHoleToLarge = this.blinkHoleToLarge.bind(this);
        this.switchCircles = this.switchCircles.bind(this);
        this.openSearchFunc = this.openSearchFunc.bind(this);

        this.state = {
            classes: '',
            renderInfo: '',
            isInfoContainerOpen: false,
            circleType: 'blue',
            circleWhiteShowClass: '',
            circleBlueShowClass: 'show',
        }

        this.ingramCircleBlue   = React.createRef();
        this.ingramCircleWhite  = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return (nextState.classes !== this.state.classes ||
                nextState.renderInfo !== this.state.renderInfo ||
                nextState.isInfoContainerOpen !== this.state.isInfoContainerOpen ||
                nextState.circleType !== this.state.circleType ||
                nextState.circleWhiteShowClass !== this.state.circleWhiteShowClass ||
                nextState.circleBlueShowClass !== this.state.circleBlueShowClass
        );
    }

    switchCircles()
    {
        var currentCircle = this.state.circleType;

        if (currentCircle === "blue")
        {
            this.blinkHoleToSmall();

            this.setState({
                circleType: "white"
            });
        }
        else
        {
            this.blinkHoleToLarge();

            this.setState({
                circleType: "blue"
            });
        }
    }

    blinkHoleToSmall()
    {
        this.setState({
            classes: "blinkToSmall"
        });
    }

    blinkHoleToLarge()
    {
        this.setState({
            classes: "blinkToLarge"
        });
    }

    openInfoContainer(arrowLinkId)
    {
        var currentCircle = this.state.circleType;

        if (currentCircle === "blue")
        {
            switch (arrowLinkId)
            {
				case 'arrowLink_educate':
                    this.props.changeBackgroundFunc("backgroundEducate");
                    break;
				case 'arrowLink_market':
                    this.props.changeBackgroundFunc("backgroundMarket");
                    break;
				case 'arrowLink_enable':
                    this.props.changeBackgroundFunc("backgroundEnable");
                    break;
				case 'arrowLink_deliver':
                    this.props.changeBackgroundFunc("backgroundDeliver");
                    break;
				case 'arrowLink_recover':
                    this.props.changeBackgroundFunc("backgroundRecover");
                    break;
                default:
                    this.props.changeBackgroundFunc("");
                    break;
            }

            this.ingramCircleBlue.current.activateCorrespondingArrowLink(arrowLinkId);
        }

        this.setState({
            isInfoContainerOpen: true,
            renderInfo: arrowLinkId
        });

    }

    closeInfoContainer()
    {
        this.props.changeBackgroundFunc("");

        var currentCircle = this.state.circleType;

        if (currentCircle === "blue")
        {
            this.ingramCircleBlue.current.resetAllArrowLinks();
        }
        else if (currentCircle === "white")
        {
            this.ingramCircleWhite.current.showExtraElements();
        }

        this.setState({
            isInfoContainerOpen: false,
        });
    }
	
	openSearchFunc()
	{
        this.props.changeWindowFunc("search");
	}

    render()
    {
        var pushedLeftClass;
        var holeShrinkClass;

        if (this.state.isInfoContainerOpen)
        {
            pushedLeftClass = "pushed-left";
            holeShrinkClass = "shrink"
        }
        else
        {
            pushedLeftClass = "";
            holeShrinkClass = "";
        }

        var circle;

        switch (this.state.circleType)
        {
            case "white":
                circle = <IngramCircleWhite
                            ref={this.ingramCircleWhite}
                            className={this.state.circleWhiteShowClass}
                            switchCircleFunc={this.switchCircles}
                            openInfoContainerFunc={this.openInfoContainer.bind(this)}
                            isInfoContainerOpen={this.state.isInfoContainerOpen}
							
                />;

                // Apply transition/show effects after the circle blink animation has completed
                setTimeout(() => {
                    this.setState({
                        circleWhiteShowClass: "show",
                        circleBlueShowClass: "",
                    })
                }, 800);

                break;

            default:
                circle = <IngramCircleBlue
                    ref={this.ingramCircleBlue}
                    className={this.state.circleBlueShowClass}
                    openInfoContainerFunc={this.openInfoContainer.bind(this)}
                    isInfoContainerOpen={this.state.isInfoContainerOpen}
                    switchCircleFunc={this.switchCircles}
					renderInfo={this.state.renderInfo}
					openSearchFunc={this.openSearchFunc}
                />;

                // Apply transition/show effects after the circle blink animation has completed
                setTimeout(() => {
                    this.setState({
                        circleWhiteShowClass: "",
                        circleBlueShowClass: "show",
                    });
                }, 800);

                break;
        }

        return (
            <React.Fragment>
                <Container fluid
                    id="container_left"
                    className={"h-100 position-absolute " + pushedLeftClass}>

                    <div id="div_hole"
                        className={this.state.classes + " " + holeShrinkClass}>

                        {circle}
                    </div>
                </Container>

                <InfoContainer
                    id="container_right"
                    className={"position-absolute " + pushedLeftClass}
                    renderInfo={this.state.renderInfo}
                    closeInfoContainerFunc={this.closeInfoContainer.bind(this)}
                />
            </React.Fragment>
        );
    }
}

export default WindowMain;