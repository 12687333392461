import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import './ContentSearch.css';
import database from '../../database.json'
import SearchResults from '../../SearchResults/SearchResults';


class ContentSearch extends Component {

    constructor(props)
    {
        super(props);

        this.handleSearchSubmit             = this.handleSearchSubmit.bind(this);
        this.handleCompleteListingClick     = this.handleCompleteListingClick.bind(this);
        this.search                         = this.search.bind(this);  

        // Deprecating separate search database -uschej03 2/21/22
        //this.searchDatabase = database.data_search;

        this.searchDatabase = this.buildSearchDatabase(database.data_sections);

        this.state = {
            displaySearchResults: false,
            placeHolder: "Search",
            results: [],
        }
    }

    handleSearchSubmit(e)
    {
        if (e.key === "Enter")
        {
            var searchInput = e.target.value;

            this.search(searchInput); 

            this.openSearchResults();
        }       
    }

    handleCompleteListingClick(e)
    {
        this.search("");
        this.openSearchResults();
    }

    buildSearchDatabase(data_sections)
    {
        var searchDatabase = [];

        console.log(data_sections);

        data_sections.deliver.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        data_sections.educate.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        data_sections.enable.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        data_sections.market.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        data_sections.recover.services.forEach(
        function (element)
        {
            var searchEntry = {
                "keyword": element.title,
                "contact": element.contactLink,
                "learnMore": element.infoLink
            };

            searchDatabase.push(searchEntry);
        });

        return searchDatabase;
    }

    search(query)
    {
        var results = [];

        if (query === "")
        {
            results = this.searchDatabase;
            query = "Search";
        }
        else
        {
            this.searchDatabase.forEach(
            function (element)
            {
                if (element.keyword.toLowerCase().includes(query.toLowerCase()))
                {
                    results.push(element);
                }
            });
        }

        this.setState({
            results: results,
            placeHolder: query,
        });
    }

    openSearchResults()
    {
        this.setState({
            displaySearchResults: true, // Opens the search results window
        });
    }

    
    render()
    {
        var mainSearchClasses;

        if (this.state.displaySearchResults)
        {
            mainSearchClasses = "d-none";
        }

        return (
            <React.Fragment>
                <Container className={"pt-4 pb-4 black-background-tint " + mainSearchClasses}>
                    <Row className="justify-content-center">
                        <Col xs="10 text-center">
                            <input
                                id="input_mobile_mainSearch"
                                type="text"
                                className="form-control text-center mb-3"
                                placeholder="Find contacts and resources"
                                onKeyDown={this.handleSearchSubmit} />

                            <button
                                type="button"
                                className="btn btn-search text-uppercase px-4 font-weight-bold"
                                onClick={this.handleCompleteListingClick}>
                                    View Complete Listing
                            </button>
                        </Col>
                    </Row>
                    
                </Container>
                <SearchResults 
                        results={this.state.results}
                        displaySearchResults={this.state.displaySearchResults}
                        searchFunc={this.search} 
                        placeHolder={this.state.placeHolder} />
            </React.Fragment>
        );
    }
}

export default ContentSearch;