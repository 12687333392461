import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import './ContentHome.css';

import IconEducate from "./img/icon_educate.png"
import IconMarket from "./img/icon_market.png"
import IconEnable from "./img/icon_enable.png"
import IconDeliver from "./img/icon_deliver.png"
import IconRecover from "./img/icon_recover.png"
import IngramCircle from "./img/ingramcircle.png"

class ContentHome extends Component {

    constructor(props)
    {
        super(props);

        this.handleEducateClick = this.handleEducateClick.bind(this);
        this.handleMarketClick  = this.handleMarketClick.bind(this);
        this.handleEnableClick  = this.handleEnableClick.bind(this);
        this.handleDeliverClick = this.handleDeliverClick.bind(this);
        this.handleRecoverClick = this.handleRecoverClick.bind(this);
    }

    handleEducateClick(e)
    {
        this.props.changeContentFunc('educate');
    }

    handleMarketClick(e)
    {
        this.props.changeContentFunc('market');
    }

    handleEnableClick(e)
    {
        this.props.changeContentFunc('enable');
    }

    handleDeliverClick(e)
    {
        this.props.changeContentFunc('deliver');
    }

    handleRecoverClick(e)
    {
        this.props.changeContentFunc('recover');
    }
    
    render()
    {
        var hiddenClass = "";

        if (this.props.hideContent)
        {
            hiddenClass = "d-none";
        }

        return (
            <React.Fragment>
                <div id="div_mobile_hole_base" class={"text-center" + hiddenClass}>
                    {/* <div id="div_mobile_hole" class="ml-auto mr-auto">
                    </div> */ }
                    <img id="img_mobileIngramCircle" src={IngramCircle} />
                    <p class="text-center mt-5">v</p>
                </div>

                <Container id="container_pillars" className={hiddenClass}>
                    <Row className="gray-border-bottom pt-4">
                        <Col className="text-center" onClick={this.handleEducateClick}>
                            <img
                                src={IconEducate}
                                alt=""
                                className="img-fluid"
                                width="100px"
                            />
                            <p className="mt-4">Educate</p>
                        </Col>
                    </Row>

                    <Row className="gray-border-bottom pt-4">
                        <Col className="text-center" onClick={this.handleMarketClick}>
                            <img
                                src={IconMarket}
                                alt=""
                                className="img-fluid"
                                width="100px"
                            />
                            <p className="mt-4">Market</p>
                        </Col>
                    </Row>

                    <Row className="gray-border-bottom pt-4">
                        <Col className="text-center" onClick={this.handleEnableClick}>
                            <img
                                src={IconEnable}
                                alt=""
                                className="img-fluid"
                                width="100px"
                            />
                            <p className="mt-4">Enable</p>
                        </Col>
                    </Row>

                    <Row className="gray-border-bottom pt-4">
                        <Col className="text-center" onClick={this.handleDeliverClick}>
                            <img
                                src={IconDeliver}
                                alt=""
                                className="img-fluid"
                                width="100px"
                            />
                            <p className="mt-4">Deliver</p>
                        </Col>
                    </Row>

                    <Row className="pt-4">
                        <Col className="text-center" onClick={this.handleRecoverClick}>
                            <img
                                src={IconRecover}
                                alt=""
                                className="img-fluid"
                                width="100px"
                            />
                            <p className="mt-4">Recover</p>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default ContentHome;