import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import './InfoContainer.css';
import SectionContainer from "../Layouts/LayoutDesktop/SectionContainer"
import BackButtonImg from "./img/backbutton.png"

class InfoContainer extends Component
{
    constructor(props)
    {
        super(props);

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleMouseOver  = this.handleMouseOver.bind(this);
        this.handleMouseOut   = this.handleMouseOut.bind(this);

        this.state = {
            hoverMouseClass: "",
        }
    }

    handleButtonClick(e)
    {
        this.props.closeInfoContainerFunc();
    }


    handleMouseOver(e)
    {
        this.setState({
            hoverMouseClass: "hover"
        });
    }

    handleMouseOut(e)
    {
        this.setState({
            hoverMouseClass: ""
        });
    }

    render()
    {
        var sectionToRender;

        switch (this.props.renderInfo)
        {
            case "popup_educate":
			case "arrowLink_educate":
                sectionToRender = "educate";
                break;

            case "popup_market":
			case "arrowLink_market":
                sectionToRender = "market";
                break;

            case "popup_enable":
			case "arrowLink_enable":
                sectionToRender = "enable";
                break;

            case "popup_deliver":
			case "arrowLink_deliver":
                sectionToRender = "deliver";
                break;

            case "popup_recover":
			case "arrowLink_recover":
                sectionToRender = "recover";
                break;
                
            default:
                break;
        }

        return (

            <Container fluid
                id={this.props.id}
                className={'infoContainer pl-4 ' + this.props.className}>

                <Row>
                    <Col>
                        <div className="backButton">
                            <img
                                src={BackButtonImg}
                                onClick={this.handleButtonClick}
                                onMouseOver={this.handleMouseOver}
                                onMouseOut={this.handleMouseOut}
                                className={"img_backButton " + this.state.hoverMouseClass}
                                alt=""
                            />
                            <p className="text-uppercase" onClick={this.handleButtonClick}>Back</p>
                        </div>
                    </Col>
                </Row>

                <SectionContainer
                    section={sectionToRender} />
                    
            </Container>
        );
    }
}

export default InfoContainer;