import React, { Component } from 'react';

import './IngramCircleBlue.css';

import ArrowLink from "./ArrowLink";
import Popup from "../Popup/Popup"

import IngramCirclePng from './img/ingram.png'

import RecoverPng from './img/recover.png'
import RecoverPngHover from './img/recover_hover.png'
import RecoverPngActive from './img/recover_active.png'

import EducatePng from './img/educate.png'
import EducatePngHover from './img/educate_hover.png'
import EducatePngActive from './img/educate_active.png'

import MarketPng from './img/market.png'
import MarketPngHover from './img/market_hover.png'
import MarketPngActive from './img/market_active.png'

import EnablePng from './img/enable.png'
import EnablePngHover from './img/enable_hover.png'
import EnablePngActive from './img/enable_active.png'

import DeliverPng from './img/deliver.png'
import DeliverPngHover from './img/deliver_hover.png'
import DeliverPngActive from './img/deliver_active.png'
import PillarIcon from './PillarIcon';

import database from '../../database.json'

class IngramCircle extends Component {

    constructor(props)
    {
        super(props);
        this.handleMouseClick = this.handleMouseClick.bind(this);
        this.handleMouseOver  = this.handleMouseOver.bind(this);
        this.handleMouseOut   = this.handleMouseOut.bind(this);

        this.state = {
            currentActiveArrowLink: null,
            circleHoverClass: '',
        };

        this.popupEducate   = React.createRef();
        this.popupMarket    = React.createRef();
        this.popupEnable    = React.createRef();
        this.popupDeliver   = React.createRef();
        this.popupRecover   = React.createRef();

        this.arrowLinkEducate   = React.createRef();
        this.arrowLinkMarket    = React.createRef();
        this.arrowLinkEnable    = React.createRef();
        this.arrowLinkDeliver   = React.createRef();
        this.arrowLinkRecover   = React.createRef();

        this.pillarIconEducate  = React.createRef();
        this.pillarIconMarket   = React.createRef();
        this.pillarIconEnable   = React.createRef();
        this.pillarIconDeliver  = React.createRef();
        this.pillarIconRecover  = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return (nextState.currentActiveArrowLink !== this.state.currentActiveArrowLink ||
                nextProps.className !== this.props.className ||
                nextState.circleHoverClass !== this.state.circleHoverClass ||
                nextProps.isInfoContainerOpen !== this.props.isInfoContainerOpen
        );
    }

    // When the Ingram Blue Circle center is clicked
    handleMouseClick(e)
    {
        // Close any open ArrowLink popups
        if (this.state.currentActiveArrowLink != null)
        {
            this.state.currentActiveArrowLink.executeMouseOutActions();
        }

        this.props.openSearchFunc();      
    }

    handleMouseOver(e)
    {        
        this.setState({
            circleHoverClass: "hover"
        });        
    }

    handleMouseOut(e)
    {
        this.setState({
            circleHoverClass: ""
        });
    }

    handleArrowLinkMouseOver(arrowLinkObj)
    {
        if (!this.props.isInfoContainerOpen)
        {
            if (this.state.currentActiveArrowLink != null)
            {
                this.state.currentActiveArrowLink.executeMouseOutActions();
            }

            arrowLinkObj.executeMouseOverActions();       
            
            switch (arrowLinkObj.props.id)
            {
                case "arrowLink_recover":
                    break;
                default:
                    break;
            }
        }
		else
		{
			arrowLinkObj.changeImageActiveOn();
		}
		
		this.setState({
			currentActiveArrowLink: arrowLinkObj
		});
    }
	
	handleArrowLinkMouseOut(arrowLinkObj)
	{
		if (this.props.isInfoContainerOpen && arrowLinkObj.props.id !== this.props.renderInfo)
        {
			arrowLinkObj.changeImageDefault();
		}
	}

    activateCorrespondingArrowLink(popupId)
    {
        this.arrowLinkRecover.current.changeImageDefault();
        this.arrowLinkEducate.current.changeImageDefault();
        this.arrowLinkMarket.current.changeImageDefault();
        this.arrowLinkEnable.current.changeImageDefault();
        this.arrowLinkDeliver.current.changeImageDefault();

        this.state.currentActiveArrowLink.changeImageActiveOn();
    }

    resetAllArrowLinks()
    {
        this.arrowLinkRecover.current.changeImageDefault();
        this.arrowLinkEducate.current.changeImageDefault();
        this.arrowLinkMarket.current.changeImageDefault();
        this.arrowLinkEnable.current.changeImageDefault();
        this.arrowLinkDeliver.current.changeImageDefault();

        this.state.currentActiveArrowLink.changeImageDefault();
    }
	
    render()
    {
        var sectionEducate  = database.data_sections.educate;
        var sectionMarket   = database.data_sections.market;
        var sectionEnable   = database.data_sections.enable;
        var sectionDeliver  = database.data_sections.deliver;
        var sectionRecover  = database.data_sections.recover;

        return (
            <div
                id="div_ingramCircleBlue"
                className={"h-100 " + this.props.className}>

                <img
                    id="img_ingramCircleBlue"
                    src={IngramCirclePng}
                    className={this.state.circleHoverClass}
                    onClick={this.handleMouseClick}
                    onMouseOver={this.handleMouseOver}
                    onMouseOut={this.handleMouseOut}
                    alt=""
                />

                <PillarIcon
                    ref={this.pillarIconRecover}
                    id="icon_recover"
                />

                <PillarIcon
                    ref={this.pillarIconEducate} 
                    id="icon_educate"
                />

                <PillarIcon 
                    ref={this.pillarIconMarket}
                    id="icon_market"
                />

                <PillarIcon 
                    ref={this.pillarIconEnable}
                    id="icon_enable"
                />

                <PillarIcon 
                    ref={this.pillarIconDeliver}
                    id="icon_deliver"
                />               

                <ArrowLink
                    ref={this.arrowLinkRecover}
                    id="arrowLink_recover"
                    normalSrc={RecoverPng}
                    hoverSrc={RecoverPngHover}
                    activeSrc={RecoverPngActive}
                    popup={this.popupRecover}
                    pillarIcon={this.pillarIconRecover}
                    onArrowLinkMouseOver={this.handleArrowLinkMouseOver.bind(this)}
					onArrowLinkMouseOut={this.handleArrowLinkMouseOut.bind(this)}
                    isInfoContainerOpen={this.props.isInfoContainerOpen}
					openInfoContainerFunc={this.props.openInfoContainerFunc}
                />

                <ArrowLink
                    ref={this.arrowLinkEducate}
                    id="arrowLink_educate"
                    normalSrc={EducatePng}
                    hoverSrc={EducatePngHover}
                    activeSrc={EducatePngActive}
                    popup={this.popupEducate}
                    pillarIcon={this.pillarIconEducate}
                    onArrowLinkMouseOver={this.handleArrowLinkMouseOver.bind(this)}
					onArrowLinkMouseOut={this.handleArrowLinkMouseOut.bind(this)}
                    isInfoContainerOpen={this.props.isInfoContainerOpen}
					openInfoContainerFunc={this.props.openInfoContainerFunc}
                />

                <ArrowLink
                    ref={this.arrowLinkMarket}
                    id="arrowLink_market"
                    normalSrc={MarketPng}
                    hoverSrc={MarketPngHover}
                    activeSrc={MarketPngActive}
                    popup={this.popupMarket}
                    pillarIcon={this.pillarIconMarket}
                    onArrowLinkMouseOver={this.handleArrowLinkMouseOver.bind(this)}
					onArrowLinkMouseOut={this.handleArrowLinkMouseOut.bind(this)}
                    isInfoContainerOpen={this.props.isInfoContainerOpen}
					openInfoContainerFunc={this.props.openInfoContainerFunc}
                />

                <ArrowLink
                    ref={this.arrowLinkEnable}
                    id="arrowLink_enable"
                    normalSrc={EnablePng}
                    hoverSrc={EnablePngHover}
                    activeSrc={EnablePngActive}
                    popup={this.popupEnable}
                    pillarIcon={this.pillarIconEnable}
                    onArrowLinkMouseOver={this.handleArrowLinkMouseOver.bind(this)}
					onArrowLinkMouseOut={this.handleArrowLinkMouseOut.bind(this)}
                    isInfoContainerOpen={this.props.isInfoContainerOpen}
					openInfoContainerFunc={this.props.openInfoContainerFunc}
                />

                <ArrowLink
                    ref={this.arrowLinkDeliver}
                    id="arrowLink_deliver"
                    normalSrc={DeliverPng}
                    hoverSrc={DeliverPngHover}
                    activeSrc={DeliverPngActive}
                    popup={this.popupDeliver}
                    pillarIcon={this.pillarIconDeliver}
                    onArrowLinkMouseOver={this.handleArrowLinkMouseOver.bind(this)}
					onArrowLinkMouseOut={this.handleArrowLinkMouseOut.bind(this)}
                    isInfoContainerOpen={this.props.isInfoContainerOpen}
					openInfoContainerFunc={this.props.openInfoContainerFunc}
                />

				<Popup
                    ref={this.popupEducate}
                    id="popup_educate"
                    headerText={sectionEducate.head}
                    openInfoContainerFunc={this.props.openInfoContainerFunc}
                    alignment="left"
                />

                <Popup
                    ref={this.popupMarket}
                    id="popup_market"
                    headerText={sectionMarket.head}
                    openInfoContainerFunc={this.props.openInfoContainerFunc}
                    alignment="left"
                />

                <Popup
                    ref={this.popupEnable}
                    id="popup_enable"
                    headerText={sectionEnable.head}
                    openInfoContainerFunc={this.props.openInfoContainerFunc}
                    alignment="left"
                />

                <Popup
                    ref={this.popupDeliver}
                    id="popup_deliver"
                    headerText={sectionDeliver.head}
                    openInfoContainerFunc={this.props.openInfoContainerFunc}
                    alignment="right"
                />

                <Popup
                    ref={this.popupRecover}
                    id="popup_recover"
                    headerText={sectionRecover.head}
                    openInfoContainerFunc={this.props.openInfoContainerFunc}
                    alignment="right"
                />
            </div>
        );
    }
}

export default IngramCircle;