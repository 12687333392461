import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import database from '../../database.json'

import SectionBoxes from './SectionBoxes';

import './Content.css';

import ScrollDownIcon from "./img/button_down.png"

class ContentSection extends Component
{   
    constructor(props)
    {
        super(props);

        this.sectionData = database.data_sections;
    }
    render()
    {
        // Mapping props to json section data
        var sectionContent;

        switch (this.props.section)
        {
            case "educate":
                sectionContent = this.sectionData.educate;
                break;
            case "market":
                sectionContent = this.sectionData.market;
                break;
            case "enable":
                sectionContent = this.sectionData.enable;
                break;
            case "deliver":
                sectionContent = this.sectionData.deliver;
                break;
            case "recover":
                sectionContent = this.sectionData.recover;
                break;
            default:
                break;
        }

        // Build the services
        var services = sectionContent.services.map((service) =>
        {
            var serviceInfoLinkBlock;
            var serviceContactLinkBlock;
            
            if (service.hasOwnProperty("infoLink"))
            {
                serviceInfoLinkBlock =  <a href={service.infoLink} target="_blank" rel="noopener noreferrer">
                                            <img className="mr-1 img_iconInfo" alt=""/>
                                        </a>;
            }

            if (service.hasOwnProperty("contactLink"))
            {
                serviceContactLinkBlock =   <a href={service.contactLink} target="_blank" rel="noopener noreferrer">
                                                <img className="ml-1 img_iconContact" alt=""/>
                                            </a>;
            }
            
            return  (<Col xs="10" className="mt-5">
                        <p className="text-color-blue font-weight-bold mb-0">{service.title}</p>
                        <p>{service.description}</p>

                        {serviceInfoLinkBlock}
                        {serviceContactLinkBlock}
                        
                    </Col>);
        });

        var hiddenClass = "";

        if (this.props.hideContent)
        {
            hiddenClass = "d-none";
        }

        return (
            <React.Fragment>
                <Container className={"pt-4 pb-4 " + hiddenClass}>
                    <Row className="justify-content-center">
                        <Col xs="10" className="text-center">
                            <img
                                src={sectionContent.headerImage}
                                alt=""
                                className="img-fluid mb-3"
                                width="200px"
                            />
                            <p className="font-weight-bold">{sectionContent.section}</p>
                            <p className="font-weight-bold">
                                <img
                                    src={ScrollDownIcon}
                                    alt=""
                                    className="img-fluid"
                                    width="40px"
                                />
                            </p>
                        </Col>

                        <Col xs="10">
                            <h1 className="text-color-blue mb-3">{sectionContent.head}</h1>
                            <h2 className="text-color-gray mb-3">{sectionContent.intro}</h2>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        {services}
                    </Row>
                </Container>
                
                <SectionBoxes 
                    hidePillar={sectionContent.section}
                    changeContentFunc={this.props.changeContentFunc} />
                    
            </React.Fragment>
        );
    }
}

export default ContentSection;