import React, { Component } from 'react';

import './PillarIcon.css';

import IconRecoverPng from './img/recover_icon.png'
import IconEducatePng from './img/educate_icon.png'
import IconMarketPng from './img/market_icon.png'
import IconEnablePng from './img/enable_icon.png'
import IconDeliverPng from './img/deliver_icon.png'

class PillarIcon extends Component
{
    constructor(props)
    {
        super(props);

        this.activateHoverState = this.activateHoverState.bind(this);

        this.state = {
            hoverState: ""
        }
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        return (nextState.hoverState !== this.state.hoverState);
    }

    activateHoverState(activate)
    {
        if (activate)
        {
            this.setState({
                hoverState: "hover"
            }); 
        }
        else
        {
            this.setState({
                hoverState: ""
            }); 
        }
    }

    render()
    {
        var imgSrc;

        switch (this.props.id)
        {
            case "icon_recover":
                imgSrc = IconRecoverPng;
                break;
            case "icon_educate":
                imgSrc = IconEducatePng;
                break;
            case "icon_market":
                imgSrc = IconMarketPng;
                break;
            case "icon_enable":
                imgSrc = IconEnablePng;
                break;
            case "icon_deliver":
                imgSrc = IconDeliverPng;
                break;
            default:
                break;
        }

        return (
            <img
                id={this.props.id}
                className={"circleWhiteIcon " + this.state.hoverState}
                src={imgSrc}
                alt=""
            />
        );
    }
}

export default PillarIcon;