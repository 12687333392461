import React, { Component } from 'react';
import {
    Container,
} from 'reactstrap';

import './LayoutDesktop.css';
import WindowMain from "../../Windows/WindowMain";
import WindowSearch from "../../Windows/WindowSearch";

class LayoutDesktop extends Component {

    constructor(props)
    {
        super(props);
        this.handleMouseMove    = this.handleMouseMove.bind(this);
        this.changeBackground   = this.changeBackground.bind(this);
        this.changeWindow       = this.changeWindow.bind(this);

        this.state = {
            style: {
                backgroundSize: '110%',
                backgroundPositionX: 0,
                backgroundPositionY: 0
            },
            'translateX': 0,
            'translateY': 0,
            alternateBackgroundClass: '',
            windowType: 'main',
        }
    }

    changeBackground(bgClass)
    {
        this.setState({
            alternateBackgroundClass: bgClass
        });
    }

    handleMouseMove(e)
    {
        var friction = 1 / 30;
        //console.log('X: ' + e.screenX + " Y:" + e.screenY);
        //console.log('X: ' + e.clientX + " Y:" + e.clientY);

        // Constants in here create a small window in the center for movement. Anything outside
        // the window will be clipped.
        var mouseX = Math.max(-400, Math.min(400, window.innerWidth / 2 - e.clientX));
        var mouseY = Math.max(-400, Math.min(400, window.innerHeight / 2 - e.clientY));

        var followX = (80 * mouseX) / 100;
        var followY = (40 * mouseY) / 100;

        var translateX = ((followX - this.state.translateX) * friction) - 10;
        var translateY = ((followY - this.state.translateY) * friction) - 5;

        //console.log('X: ' + translateX + " Y:" + translateY);

        this.setState({
            style: {
                backgroundPositionX: translateX,
                backgroundPositionY: translateY
            },
            'translateX': translateX,
            'translateY': translateY
        });
    }

    changeWindow(windowType)
    {
        if (windowType === "search")
        {
            this.changeBackground("backgroundSearch");
        }
        else if (windowType === "main")
        {
            this.changeBackground("");
        }
        
        this.setState({
            windowType: windowType
        });
    }

    render()
    {
        var window;

        switch (this.state.windowType)
        {
            case "main":
                window = <WindowMain
                            changeBackgroundFunc={this.changeBackground}
                            changeWindowFunc={this.changeWindow}
                            />;
                break;
            default:
                break;
        }

        var windowSearchClasses = "";

        if (this.state.windowType !== "search")
        {
            windowSearchClasses += " hidden"
        }

        return (
            <Container fluid
                style={this.state.style}
                id="container_canvas"
                className={"h-100 position-relative " + this.state.alternateBackgroundClass}
                onMouseMove={this.handleMouseMove}
            >
                {window}

                <WindowSearch
                    className={"" + windowSearchClasses}
                    changeWindowFunc={this.changeWindow}
                    />

            </Container>
        );
    }
}

export default LayoutDesktop;