import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import './IconLabel.css'


class IconLabel extends Component {

    constructor(props)
    {
        super(props);

        this.handleMouseOver = this.handleMouseOver.bind(this);
    }

    handleMouseOver(e)
    {
        this.props.onIconLabelMouseOver(this);
    }

    executeMouseOverActions()
    {
        this.props.popup.current.showPopup(true);
    }

    executeMouseOutActions()
    {
        this.props.popup.current.showPopup(false);
    }

    render()
    {

        return (
            <Container
                id={this.props.id}
                className={"iconLabel " + this.props.className}
                onMouseOver={this.handleMouseOver}
            >

                <Row>
                    <Col xs="3">
                        <img
                            src= {this.props.src}
                            className="img-fluid"
                            alt=""
                        />
                    </Col>
                    <Col xs="7"
                         className="iconLabelTextMargin mb-3">
                        <h2 className={"text-uppercase"}>{this.props.labelText}</h2>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default IconLabel;