import React, { Component } from 'react';
import {
    Container,
    Navbar,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    NavLink,
    NavbarBrand,
    Row,
    Col,
} from 'reactstrap';

import './LayoutMobile.css';

import IngramLogo from "./img/ingram_logo_mobile.png"
import SearchIcon from "./img/navbar_search_mobile.png"
import ScrollUpButton from "./img/button_up.png"
import ContentContainer from './ContentContainer';

class LayoutMobile extends Component {

    constructor(props)
    {
        super(props);
        this.toggleNavbar       = this.toggleNavbar.bind(this);
        this.scrollToTop        = this.scrollToTop.bind(this);
        this.changeBackground   = this.changeBackground.bind(this);
        this.changeContent      = this.changeContent.bind(this);

        this.handleHomeClick        = this.handleHomeClick.bind(this);
        this.handleEducateClick     = this.handleEducateClick.bind(this);
        this.handleMarketClick      = this.handleMarketClick.bind(this);
        this.handleEnableClick      = this.handleEnableClick.bind(this);
        this.handleDeliverClick     = this.handleDeliverClick.bind(this);
        this.handleRecoverClick     = this.handleRecoverClick.bind(this);
        this.handleSearchIconClick  = this.handleSearchIconClick.bind(this);

        this.state = {
            navbarCollapsed: true,
            hideContent: false,
            background: 'home',
            contentPage: 'home',
        }
    }

    toggleNavbar()
    {
        this.setState({
            navbarCollapsed: !this.state.navbarCollapsed,
            hideContent: !this.state.hideContent
        });
    }

    scrollToTop()
    {
        window.scrollTo({top: 0, behavior: "smooth"})
    }

    changeBackground(background)
    {
        this.setState({
            background: background,
        });
    }

    changeContent(newContentPage)
    {
        if (newContentPage === 'educate' || newContentPage === 'market' || newContentPage === 'enable' || 
            newContentPage === 'deliver' || newContentPage === 'recover')
        {
            this.changeBackground('white');
        }
        else if (newContentPage === 'home' || newContentPage === 'search')
        {
            this.changeBackground('home');
        }

        this.setState({
            contentPage:        newContentPage,
            navbarCollapsed:    true,
        });

        this.scrollToTop();
    }

    handleHomeClick()
    {
        this.changeContent('home');
    }

    handleEducateClick()
    {
        this.changeContent('educate');
    }

    handleMarketClick()
    {
        this.changeContent('market');
    }

    handleEnableClick()
    {
        this.changeContent('enable');
    }
    
    handleDeliverClick()
    {
        this.changeContent('deliver');
    }

    handleRecoverClick()
    {
        this.changeContent('recover');
    }

    handleSearchIconClick()
    {
        this.changeContent('search');
    }

    render()
    {
        var backgroundImageClass;

        switch (this.state.background)
        {
            case 'white':
                backgroundImageClass = "background-white";
                break;
            case 'home':
            default:
                backgroundImageClass = "background-home";
                break;   
        }

        return (
            <React.Fragment>
                <div
                    id="div_mobileBackgroundLayer"
                    className={backgroundImageClass}
                >
                    <Container id="container_mobileLayout_header">
                        <div className="">
                            <Navbar color="faded" dark>
                                <NavbarToggler onClick={this.toggleNavbar} className="" />
                                <NavbarBrand
                                    className="mr-auto ml-auto"
                                    onClick={this.handleHomeClick}>
                                    <img
                                        src={IngramLogo}
                                        alt=""
                                        className="img-fluid"
                                        width="120px"
                                    />
                                </NavbarBrand>

                                <img
                                    src={SearchIcon}
                                    alt=""
                                    className="img-fluid"
                                    width="30px"
                                    onClick={this.handleSearchIconClick}
                                />
                                
                                <Collapse isOpen={!this.state.navbarCollapsed} navbar>
                                    <Nav navbar>
                                        <NavItem className="mb-3">
                                            <NavLink href="#">
                                                <span
                                                    className="text-uppercase"
                                                    onClick={this.handleEducateClick}>
                                                        Educate
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="mb-3">
                                            <NavLink href="#">
                                                <span 
                                                    className="text-uppercase"
                                                    onClick={this.handleMarketClick}>
                                                        Market
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="mb-3">
                                            <NavLink href="#">
                                                <span 
                                                    className="text-uppercase"
                                                    onClick={this.handleEnableClick}>
                                                        Enable
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="mb-3">
                                            <NavLink href="#">
                                                <span 
                                                    className="text-uppercase"
                                                    onClick={this.handleDeliverClick}>
                                                        Deliver
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className="mb-3">
                                            <NavLink href="#">
                                                <span 
                                                    className="text-uppercase"
                                                    onClick={this.handleRecoverClick}>
                                                        Recover
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Navbar>
                        </div>
                    </Container>

                    <ContentContainer 
                        hideContent={this.state.hideContent}
                        contentPage={this.state.contentPage}
                        changeContentFunc={this.changeContent}
                    />

                </div>

                <Container id="container_mobileLayout_footer" className="pt-5 pb-5">
                    <Row className="justify-content-center mb-4">
                        <Col xs="10">
                            <p>3355 Michelson Drive, Suite 100</p>
                            <p>Irvine, CA 92612</p>
                            <p>(800) 456-8000</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="10">
                            <img
                                src={IngramLogo}
                                alt=""
                                className="img-fluid"
                                width="120px"
                            />
                        </Col>
                    </Row>
                </Container>

                <Container id="container_mobileLayout_copyFooter" className="pt-4 pb-4">
                    <Row className="mb-3">
                        <Col className="text-center">
                            <a href="https://corp.ingrammicro.com/privacy-statement.aspx" target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a href="https://corp.ingrammicro.com/Terms-of-Use.aspx" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="text-center">
                            <span className="copyright">Copyright &copy; 2019 Ingram Micro. All Rights Reserved.</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <img
                                src={ScrollUpButton}
                                alt=""
                                className="img-fluid"
                                width="40px"
                                onClick={this.scrollToTop}
                            />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default LayoutMobile;